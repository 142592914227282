



























import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType, CustomFormType,
  FundProjectApplyCreateOrUpdateDto, FundProjectApplyDto, FundProjectCreateOrUpdateDto, FundProjectDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";

@Component({
  components: {
    CustomFormEdit
  },
})
export default class CreateFundMaintain extends Vue {
  dataId = 0;
  btnDisabled = false;
  defaultData: FundProjectApplyDto = {};

  get getData(): Promise<FundProjectApplyDto> {
    if (this.dataId > 0) return api.fundProjectApply.get({id: this.dataId});
    else return new Promise<FundProjectApplyDto>(resolve => this.defaultData);
  }

  get hostType() {
    return CustomFormType.FundProject;
  }

  get hostTypeFile() {
    return AttachmentHostType.FundProjectApply;
  }
  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
    }
  }

  handleSave(): void {
    this.btnDisabled = true;
    (this.$refs.customForm as any).validate(async (valid: boolean) => {
      if (valid) {
        const data = (
          this.$refs.customForm as any
        ).getRequestData() as FundProjectApplyCreateOrUpdateDto;
        let fn;
        if (this.dataId>0) fn = api.fundProjectApply.update;
        else fn = api.fundProjectApply.create;
        data.extensionData = '';
        fn({body: data}).then((res) => {
          this.$message.success("更新成功");
          this.$router.push({
            path: "/fundProject/fundProjectApply",
          });
        });
      } else {
        this.$message.error("请检查表单");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.$router.back();
  }

}
